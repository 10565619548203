import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'MIC';
}

export function emailValidator(control: FormControl): { [key: string]: any } {
  var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  if (control.value && !emailRegexp.test(control.value)) {
    return { invalidEmail: true };
  }
}

('use strict');

// export const baseUrl = 'http://localhost:8000/api/ia'; //  localhost
//  export const baseUrl = 'https://qaiaapi.mic.gov.in/api/ia'; //  QA
// export const baseUrl = 'https://iaapi.mic.gov.in/api/ia'; //  Production



// export const baseUrl = 'https://qamicapi.aicte-india.org/api/ia'; //QA-this
//export const baseUrl = 'https://qamicapi.aicte-india.org/api';  //  QA
//export const baseUrl = 'https://qamic2api.mic.gov.in/api/ia';  //  QA
// export const baseUrl = 'https://api.mic.gov.in/api/ia'; //  Production

//export const baseImageUrl = 'https://qamicapi.aicte-india.org';  //  QA
//export const baseImageUrl =  'https://qamicapi.aicte-india.org/';  //  local
// export const baseImageUrl = 'https://api.mic.gov.in/uploads/institutes'; //  production
// export const baseImageUrl = 'https://api.mic.gov.in/uploads/institutes'; //  production
// export const baseImageUrl = 'https://iaapi.mic.gov.in/uploads/institutes'; //  production
// export const baseImageUrl = 'https://miciicsta01.blob.core.windows.net/miciiccontainer1/uploads/institutes'; //  Azure

// export const baseImageUrl = 'https://qamicapi.aicte-india.org';  //  QA
// export const baseImageUrl =  'https://qamicapi.aicte-india.org/';  //  local
// export const baseImageUrlIA = 'https://api.mic.gov.in/uploads/ia'; //  production
// export const baseImageUrlIA = 'https://iaapi.mic.gov.in/uploads/ia'; //  production
// export const baseImageUrlIA = 'https://miciicsta01.blob.core.windows.net/miciiccontainer1/uploads/ia'; //  Azure
// export const baseImage = 'https://iaapi.mic.gov.in/uploads/'; //  production
// export const baseImage = 'https://miciicsta01.blob.core.windows.net/miciiccontainer1/uploads/'; //  Azure


export const baseUrl = environment.baseUrl;
export const baseImageUrl = environment.baseUrl;
export const baseImageUrlIA = environment.baseImageUrlIA;
export const baseImage = environment.baseImage; 
