import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RequestResetComponent } from './components/password/request-reset/request-reset.component';
import { ResponseResetComponent } from './components/password/response-reset/response-reset.component';
import { BeforeLoginService } from './Services/before-login.service';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { LoginComponent } from './components/shared/login/login.component';

const appRoutes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'request-password-reset',
    component: RequestResetComponent,
  },
  {
    path: 'forgot-password-response',
    component: ResponseResetComponent,
    canActivate: [BeforeLoginService],
  },
  {
    path: 'verify-email',
    component: EmailVerificationComponent,
    canActivate: [BeforeLoginService],
  },
  {
    path: 'ambassador',
    loadChildren: () =>
      import('./components/users/ambassador/ambassador.module').then(
        (m) => m.AmbassadorModule
      ),
  },
  {
    path: '**',
    component: LoginComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(appRoutes)],
})
export class AppRoutingModule {}
