import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BrowserModule } from '@angular/platform-browser';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { RequestResetComponent } from './components/password/request-reset/request-reset.component';
import { ResponseResetComponent } from './components/password/response-reset/response-reset.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { JarwisService } from './Services/jarwis.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { httpInterceptorProviders } from './http-interceptors';
import { FooterComponent } from './components/shared/footer/footer.component';
import { UsersComponent } from './components/users/users.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { SelectCheckAllComponent } from './components/select-check-all/select-check-all.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { InnerNavbarComponent } from './components/shared/inner-navbar/inner-navbar.component';
import { InnerFooterComponent } from './components/shared/inner-footer/inner-footer.component';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InstituteFooterComponent } from './components/shared/institute-footer/institute-footer.component';
import { InstituteInnverNvavbarComponent } from './components/shared/institute-innver-nvavbar/institute-innver-nvavbar.component';
import { InstituteInnerFooterComponent } from './components/shared/institute-inner-footer/institute-inner-footer.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { GaugeChartModule } from 'angular-gauge-chart';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatChipsModule } from '@angular/material/chips';
import { AfterLoginAmbassadorService } from './Services/after-login.service';
import { LoginComponent } from './components/shared/login/login.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { PasswordResetDialogComponent } from './components/users/ambassador/password-reset-dialog/password-reset-dialog.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AmbassadorStatusComponent } from './components/users/ambassador/ambassador-status/ambassador-status.component';
import { FormComponent } from './components/users/ambassador/mentorship/form/form.component';
import { ScheduleDialogComponent } from './components/users/ambassador/report/report-clinic/schedule-dialog/schedule-dialog.component';
// import { MatSliderModule } from '@angular/material/slider';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { YouTubePlayerModule } from "@angular/youtube-player";
import { RescaleCompComponent } from './components/rescale-training/rescale-comp/rescale-comp.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    RequestResetComponent,
    ResponseResetComponent,
    EmailVerificationComponent,
    FooterComponent,
    UsersComponent,
    SelectCheckAllComponent,
    InnerNavbarComponent,
    InnerFooterComponent,
    LoaderComponent,
    InstituteFooterComponent,
    InstituteInnverNvavbarComponent,
    InstituteInnerFooterComponent,
    LoginComponent,
    AmbassadorStatusComponent,
    RescaleCompComponent
  ],
  imports: [
    YouTubePlayerModule,
    // MatSliderModule,
    // MatSlideToggleModule,
    BrowserModule,
    // NgxAutocompleteModule,
    AutocompleteLibModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatDialogModule,
    MatInputModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: false,
    }),
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCardModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    NgxChartsModule,
    ChartsModule,
    GaugeChartModule,
    NgxPaginationModule,
    MatChipsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatAutocompleteModule
  ],
  exports: [
    BrowserModule,
    // NgxAutocompleteModule,
    AutocompleteLibModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCardModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    NgxChartsModule,
    ChartsModule,
    GaugeChartModule,
    NgxPaginationModule,
    MatChipsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    Ng2SearchPipeModule
  ],
  providers: [
    httpInterceptorProviders,
    JarwisService,
    AfterLoginAmbassadorService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
