import { Injectable } from '@angular/core';
import * as myGlobals from '../app.component';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor() {}

  private iss = {
    login: myGlobals.baseUrl + '/login',
    signup: myGlobals.baseUrl + '/signup',
  };

  handle(token) {
    this.set(token);
    this.isValid();
  }

  set(token) {
    localStorage.setItem('token', token);
  }

  get() {
    return localStorage.getItem('token');
  }

  remove() {
    localStorage.removeItem('token');
  }

 getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) return null;

    const date = new Date(0); 
    date.setUTCSeconds(decoded.exp);
    return date;
  }
  
isTokenExpired(token?: string): boolean {
    if(!token) token = this.get();
    if(!token) return true;

    const date = this.getTokenExpirationDate(token);
    if(date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }
  isValid() {
    const token = this.get();
    if (token) {
      const payload = this.payload(token);
      return Object.values(this.iss).indexOf(payload.iss) > -1 ? true : false;
    }
    return false;
  }

  payload(token) {
    const payload = token.split('.')[1];
    return this.decode(payload);
  }

  decode(payload) {
    return JSON.parse(atob(payload));
  }

  loggedIn() {
    return this.isValid();
  }
}
