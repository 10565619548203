import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { TokenService } from './token.service';
import { HttpClient } from '@angular/common/http';
import * as myGlobals from '../app.component';
import { AmbassadorAuthService } from './ambassador/ambassador-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AfterLoginService implements CanActivate {
  constructor(private token: TokenService) {}

  canActivate(
    route: import('@angular/router').ActivatedRouteSnapshot,
    state: import('@angular/router').RouterStateSnapshot
  ):
    | boolean
    | import('@angular/router').UrlTree
    | import('rxjs').Observable<boolean | import('@angular/router').UrlTree>
    | Promise<boolean | import('@angular/router').UrlTree> {
    if (localStorage.getItem('token')) {
      return true;
    } else {
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class AfterLoginAmbassadorService implements CanActivate {
  private baseUrl = myGlobals.baseUrl;

  constructor(
    private token: TokenService,
    private http: HttpClient,
    private ambassadorService: AmbassadorAuthService
  ) {}

  canActivate(
    route: import('@angular/router').ActivatedRouteSnapshot,
    state: import('@angular/router').RouterStateSnapshot
  ):
    | boolean
    | import('@angular/router').UrlTree
    | import('rxjs').Observable<boolean | import('@angular/router').UrlTree>
    | Promise<boolean | import('@angular/router').UrlTree> {
    if (this.ambassadorService.checkPasswordReset()) {
      return true;
    } else {
      return false;
    }
  }
}
