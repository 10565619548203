import { AmbassadorAuthService } from 'src/app/Services/ambassador/ambassador-auth.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-request-reset',
  templateUrl: './request-reset.component.html',
  styleUrls: ['./request-reset.component.css'],
})
export class RequestResetComponent implements OnInit {
  constructor(
    private ambassadorAuthService: AmbassadorAuthService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {}

  public form = {
    email: null,
  };

  onSubmit() {
    this.ambassadorAuthService.forgotPassword(this.form.email).subscribe(
      (data) => this.handleResponse(data),
      (error) => this.handleError(error)
    );
  }

  handleResponse(data) {
    this.form.email = null;
    if (data['message'] == 'Success') {
      this.toastr.success('Password has been send to registered mail id.');
      this.toastr.success('Please check your inbox.');
    } else {
      if (data['status'] == '0') {
        this.toastr.error(data['msg']);
      } else {
        this.toastr.error('Some error occured. Please try again!');
      }
    }
  }

  handleError(error) {
  }
}
