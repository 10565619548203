import { Injectable } from '@angular/core';
import * as myGlobals from '../../app.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AmbassadorAuthService {
  private baseUrl = myGlobals.baseUrl;

  constructor(private http: HttpClient) {}

  getProgram(id) {
    return this.http.get(`${this.baseUrl}/getBootcampParticipate/` + id);
  }

  postInstituteName(id) {
    const data = {
      id: id,
    };
    return this.http.post(`${this.baseUrl}/getBootcampInstituteDetails`, data);
  }

  submitSignup(data) {
    return this.http.post(`${this.baseUrl}/registerIA `, data);
  }

  postTraining(id) {
    const data = {
      id: id,
    };
    return this.http.post(`${this.baseUrl}/getBootcampInstitute`, data);
  }

  checkRole() {
    return this.http.get(`${this.baseUrl}/checkRole`);
  }

  checkPasswordReset() {
    return this.http.get(`${this.baseUrl}/iaResetPasswordStatus`);
  }

  forgotPassword(email) {
    const data = {
      email: email,
    };
    return this.http.post(`${this.baseUrl}/iaForgotPassword`, data);
  }

  resetPassword(data) {
    return this.http.post(`${this.baseUrl}/iaResetPassword`, data);
  }
}
