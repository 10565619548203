import { Injectable } from '@angular/core';
import * as myGlobals from '../app.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class JarwisService {
  private baseUrl = myGlobals.baseUrl;

  constructor(private http: HttpClient) { }

  signUp(data) {
    return this.http.post(`${this.baseUrl}/register`, data);
  }

  getAisheDetails(data) {
    const aicteCode = {
      aishe_code: data,
    };
    return this.http.get(`${this.baseUrl}/aishe-details/` + data);
  }

  login(data) {
    return this.http.post(`${this.baseUrl}/login`, data);
  }

  sendPasswordResetLink(data) {
    return this.http.post(`${this.baseUrl}/forgot-password-request`, data);
  }

  changePassword(data) {
    let headers = new HttpHeaders();
    const authToken = 'Bearer ' + data['resetToken'];

    headers.append('Authorization', authToken);
    return this.http.post(`${this.baseUrl}/forgot-password-response`, data, {
      headers: headers
    });
  }


  emailVerification(data) {
    return this.http.post(`${this.baseUrl}/emailVerification`, data);
  }

  remove() {
    localStorage.removeItem('token');
  }
}
