<link rel="stylesheet" type="text/css" href="/assets/iaProfile/css/bootstrap.min.css" />
<link rel="stylesheet" href="/assets/iaProfile/css/style.css" type="text/css" />

<div id="outer_wrapper">
  <div class="container-fluid">
    <div id="inner_wrapper" class="row">
      <div id="inner_content" class="col-sm-12">
	  <!-- <marquee behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();"><p><a href="assets/announcements/IA_Announcement.pdf" target="_blank">MIC's Innovation Ambassador Training has started for second phase nominees from 10th August 2021.Details about training have been sent to their registered email.</a></p> </marquee> -->
        <div id="form_img" class="col-sm-8">
          <img src="/assets/iaProfile/img/bg-01.jpg" class="img-responsive" alt="" />
        </div>
        <div id="form_text" class="col-sm-4">
          <h2>Innovation Ambassador</h2>
          <div class="col-sm-12">
            <img src="/assets/iaProfile/img/login-icon.png" class="img-responsive" alt="" />
            <h4>Login to continue New IA</h4>
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit(captcha_response.value)">
              <div class="form-group">
                <label for="email">Email address:</label>
                <input formControlName="email" type="email" class="form-control" id="email"
                  placeholder="enter email address" required="true" />
              </div>
              <div class="form-group form-groupLogin">
                <label for="pwd">Password:</label>
                <input type="password" formControlName="password" class="form-control" id="pwd"
                  placeholder="enter password" required="true" />
              </div>
              <div class="form-group form-groupLogin">
                <!-- <re-captcha (resolved)="resolved($event)" siteKey="6Lc0dcoZAAAAAGVDDIpIcXdxnFmpl_8iCDhv56hT"></re-captcha> -->
                <div class="captchaContainer">
                  <div class="captchaBlock">
                    <div class="captchaText">
                    <canvas id="canvas2" #canvas2></canvas>
                    </div>
                    <input
                    type="text"
                    class="captchaInputText"
                    [maxLength]="6"
                    [minLength]="6"
                    #captcha_response
                    id="captcha_response"
                    placeholder="Enter Text Here"
                    />
                  </div>
                  <a class="captchaRefreshBtn" (click)="createCaptcha()"><mat-icon>cached</mat-icon></a>
                </div>
              </div>
              <button type="button" class="btn btn-success btnLogin" type="submit">
                Login
              </button>
              <span class="forgotPass" routerLink="/request-password-reset">Forgot Password</span>
              <!-- <span class="forgotPass" data-toggle="modal" data-target="#myModalPassword">Forgot Password</span> -->
            </form>
            <div class="clearfix"></div>
            <!-- <p>
              <a routerLink="/ambassador/sign-up" class="iaLoginSignupButton">Innovation Ambassador Register Here</a>
            </p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
