<link rel="stylesheet" type="text/css" href="/assets/iaProfile/css/bootstrap.min.css" />
<link rel="stylesheet" href="/assets/iaProfile/css/style.css" type="text/css">

  <!-- <nav class="navbar navbar-default navbar-fixed-top">
    <div class="container-fluid navigation_width">
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a class="navbar-brand" href="@"><img src="assets/institute/images/logo.png" class="img-responsive"></a>
      </div>
      <div class="collapse navbar-collapse" id="myNavbar">
        <ul class="nav navbar-nav navbar-right" style="width:auto;float: left !important;right:40px; top: 0;">
          <li><span style="font-size: 27px;">MHRD’s Innovation Cell<br>Institution’s Innovation Council</span><br>Institute Portal</li>
        </ul>
      </div>
    </div>
  </nav> -->
  <div id="outer_wrapper">
<div class="container">
<mat-card class="matr">

			<div class="login100-form-title p-b-51" style="text-align:center;">
			<img src="../../../../../assets/img/logoiic.png" class="iaSignLogo">
		</div>
			
			<form class="login100-form validate-form flex-sb flex-w" #RequestResetForm=ngForm (ngSubmit)="onSubmit()">
			
			<div style="margin-top: 20px;margin-bottom: 20px; text-align:center;">
				<h4 style="font-size: 100%;font-weight: 600;">
					Forgot Password?</h4>
			</div>
				<div class="form-group wrap-input100 validate-input m-b-16">
					<!-- <label class="control-label col-sm-2" for="email">Email:</label> -->
						<input type="email" name="email" placeholder="Enter Email Id" class="input100" [(ngModel)]="form.email" id="inputEmail3" required
              autocomplete="off">
				</div>
				

				
				<div class="form-group pull-left" style="padding-top: 10px;">
					<label>
						<a routerLink="/login" class="float-right"><span class="loginLink">Sign In</span></a>
					</label>
				</div>
				<!--
				<div class="form-group pull-left" style="padding-top: 10px;">
					<label>
						<a routerLink="/ambassador/sign-up" class="float-left"><span class="loginLink">New User?</span></a>
					</label>
				</div>
				-->
				<div class="container-login100-form-btn m-t-17 boxResetBut">
					<button type="submit" class="mat-elevation-z4 mt-3 iaButReset" [disabled]="!RequestResetForm.valid">
            <!-- <mat-icon class="mt-2"> arrow_forward_ios</mat-icon> -->Submit
          </button>
				</div>
			</form>

</mat-card>
</div>
</div>

  
<!-- Footer
<app-institute-footer></app-institute-footer>  -->
  