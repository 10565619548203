import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TokenService } from './token.service';
import * as myGlobals from '../app.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl = myGlobals.baseUrl;

  private loggedIn = new BehaviorSubject<boolean>(this.token.loggedIn());

  authStatus = this.loggedIn.asObservable();

  changeStatus(value: boolean){
    this.loggedIn.next(value);
  }

  constructor(private token: TokenService, 
    private http: HttpClient) {}
 
  getOutcomeData() {
    return this.http.get(`${this.baseUrl}/micOutComeData`);
  }
  UpdateTableData(data,page) {
    return this.http.post(`${this.baseUrl}/getMicOutcome?per_page=10&page=`+ page, data);
  }
  // return this.http.get(`${this.baseUrl}/reportDetails?per_page=10&page=` + page + `&id=` + id + `&type=2`);
}
