<script src="https://use.fontawesome.com/667bb32bef.js"></script>
<div class="footer-area" style="margin-top: 0px !important;">
<div class="footer-top">
    <div class="container">
        <div class="row">
			<div class="col-md-4 col-sm-4 col col-xs-12">
                <div class="footer-widget footer-menu">
                    <h2>Quick Links</h2>
                    <ul>
                        <li><a href="http://qa.mic.gov.in"><i class="fa fa-caret-right" aria-hidden="true"></i>Home</a>
						</li>
                        <li><a href="http://qa.mic.gov.in/iic.html"><i class="fa fa-caret-right" aria-hidden="true"></i>Innovation Council</a></li>
                        <li><a href="http://www.sih.gov.in" target="_blank"><i class="fa fa-caret-right" aria-hidden="true"></i>SIH</a></li>
                        <li><a href="https://ariia.gov.in" target="_blank"><i class="fa fa-caret-right" aria-hidden="true"></i>ARIIA</a></li>
                    </ul>
                </div>
            </div>

            
            <div class="col-md-4 col-sm-4 col col-xs-12">
                <div class="footer-widget footer-menu">
                    <h2>Social Media Links</h2>
                    <ul>
                        <li><a href="https://www.twitter.com/mhrd_innovation" target="_blank"><i class="fa fa-twitter"></i> Twitter</a></li>
                        <li><a href="https://www.facebook.com/mhrds.innovationcell" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i> Facebook</a></li>
                        <li><a href="https://www.youtube.com/mhrdinnovationcell" target="_blank"><i class="fa fa-youtube" aria-hidden="true"></i> Youtube</a></li>
						<li><a href="https://www.instagram.com/mhrd.innovationcell/" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i> Instagram</a></li>
                        
                    </ul>
                </div>
            </div>
            
			<div class="col-md-4 col-sm-4 col col-xs-12">
                <div class="footer-widget footer-menu">
                    <h2>Contact Us</h2>
                    <ul>
<li><a href="http://qa.mic.gov.in"><i class="fa fa-globe" aria-hidden="true"></i> www.mic.gov.in</a></li>
<li><a href="mailto:elangovan.kariappan@gov.in"><i class="fa fa-envelope"></i> iic.mhrd@aicte-india.org</a></li>
<li><a href="callto:01129581332"><i class="fa fa-phone" aria-hidden="true"></i> 011 2958 1239/1513/1223</a></li>
</ul>
     
                </div>
            </div>
			
			
        </div>
    </div>
</div>
</div>
<style>
.footer-widget.footer-menu ul{margin-bottom:0px;}
.footer-area{background: #0d133d;margin-top:25px;}
.footer-area ul {list-style-type:none;}
.footer-area li{font-size: 16px !important;}
.footer-area li i{color: #f9a72a;  }
.footer-top{padding: 14px 40px !important;}
.footer-widget{font-size: 14px !important;}
.footer-widget h2{font-size: 18px;color: #fff;margin: 0 0 1.5em;padding-bottom: 0.7em;text-transform: uppercase;position: relative;	letter-spacing: 1px;border-bottom:1px solid #ffd700;}
.footer-widget h2::before{
content: "";
    background-color: #fff;
    width: 60px;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 44px;
}
.contact ul li{position: relative;padding-left: 25px;margin-bottom: 10px;color: #b3b3b3;}
.contact ul li i{position: absolute;left: 0;top: 6px;}
.footer-menu ul li{    margin-bottom: 0px;line-height: 28px;}
.footer-menu ul li a{ color: #fff;font-size: 16px;line-height: 28px; }
.footer-menu ul li a i{display: inline-block;padding-right: 5px;}
.recent-post ul li{overflow: hidden;margin-bottom: 15px;}
.recent-post ul li:last-child{margin-bottom: 0px;}
.post-img{float: left;width: 30%;}
.post-ceontent{float: right;width: 65%;}
.post-ceontent h3{font-size: 14px;margin: 0 0 5px;line-height: 1.5em;}
.post-ceontent h3 a{display: block;color: #b3b3b3;}
.footer-menu ul li a:hover,.post-ceontent h3 a:hover{color: #f05e29;}
.post-ceontent span{font-size: 12px;color: #b3b3b3;}
.event-gallery ul{    overflow: hidden;}
.event-gallery ul li{width: 33.33%;float: left;margin-bottom: 10px;padding-right: 10px;}
.footer-bottom{border-top: 1px dashed #003675;    padding: 12px 0px;}
.footer-bottom p{color: #b3b3b3;font-size: 14px;}
.footer-bottom ul li{display: inline-block;margin: 0px 2px;}
.footer-bottom ul li a{width: 30px;height: 30px;line-height: 26px;font-size: 12px;color: #ccc;    border: 2px solid gray;border-radius: 50%;display: block;text-align: center;}
.footer-bottom ul li a:hover{background: #f05e29;border-color: #f05e29;}
.form-control {	border-radius: 0;}
textarea {	resize: none;}
.navbar-fixed-top.scrolled {background-color: #0d133d !important;transition: background-color 500ms linear;}
.navbar-fixed-top.scrolled .navbar-brand {	width: 68%;transition: width 500ms ease-in;}
.navbar-fixed-top.scrolled .navbar-left, .navbar-fixed-top.scrolled .navbar-right {padding: 20px 0;}
.navbar-right .social_nav:last-child {margin-left: -20px;}
.sec2{	background-color: #f05e29;}
.navbar a{	font-family: "Trebuchet MS", Helvetica, sans-serif;}
.btn-change1 {    border: 1px solid #ffffff;
    color: #ffffff;
    text-align: left;
    padding: 8px 15px;
    text-decoration: none!important;
	font-family: "Trebuchet MS", Helvetica, sans-serif;
	cursor:pointer;
	font-size: 16px;
}
.btn-change1 img{
	padding-left: 2px;
	margin-top:-2px;
	transition: padding 200ms ease;}
.btn-change1:focus,
.btn-change1:hover {
    border: 1px solid #ffffff;
    color: #ffffff;
    text-align: left;
    padding: 8px 15px;
    text-decoration: none!important;
	font-family: Gilroy;
}
.btn-change1:hover img, .btn-change1:focus img {
    padding-left: 8px;
	transition: padding 200ms ease;
}
.btn-change2 {
    border: 1px solid #f05e29;
    color: #f05e29;
    text-align: left;
    padding: 8px 15px;
    text-decoration: none!important;
	font-family: Gilroy;
	cursor:pointer;
	font-size: 16px;
}
.btn-change2 img{
	padding-left: 2px;
	margin-top:-2px;
	transition: padding 200ms ease;}
.btn-change2:focus,
.btn-change2:hover {
    border: 1px solid #f05e29;
    color: #f05e29;
    text-align: left;
    padding: 8px 15px;
    text-decoration: none!important;
	font-family: Gilroy;
}
.btn-change2:hover img, .btn-change2:focus img {
    padding-left: 8px;
	transition: padding 200ms ease;
}
.sec3 h2{
	color: #0d133d;
}
.sec3 p{color: #606060;    font-size: 16px;
    line-height: 25px;}
.box1{
	background: url(../images/box11.jpg);
}
.box1 h2{
	display:flex;
	align-items:center;
	justify-content: center;
	text-align:center;
	min-height:50vh;
	color: #ffffff;
}
.box2{
	background: url(../images/box21.jpg) center;
}
.box2 h2{
	display:flex;
	align-items:center;
	justify-content: center;
	text-align:center;
	min-height:50vh;
	color: #ffffff;
	margin: 0!important;
}
.box3{
	background: url(../images/box31.jpg) center;
}
.box3 h2{
	display:flex;
	align-items:center;
	justify-content: center;
	text-align:center;
	min-height:50vh;
	color: #ffffff;
	margin: 0!important;
}
.sec3 .col-md-6:first-child{
	padding-right: 0!important;
	padding-left: 0!important;
}

.sec3 .col-md-6:last-child{
	padding-left: 0!important;
}
.objective{
	padding: 0px 40px 0px 0;
}
.see_more_btn {
    margin-top: -80px;
}
.mem_text{
	padding: 25px;
	background: #111e5e;
}
.mem_text h5{
	margin-bottom:15px;
	color: #f05e29;
	font-size: 20px;
}
.mem_text p{
	margin-bottom:5px;    
	font-size: 15px;
    line-height: 18px;
}
.ftr-rgt p{
	font-size: 10px;
	color: #459ee0;
	margin-bottom: 0;
	line-height: 18px;
	float:right;
}
.foter li {
    padding-right: 40px;
    list-style-type: none;
    display: inline;
	font-size: 15px;
}
.foter p{
	font-size: 12px;
	line-height:22px;
}
.theme_colored2{
	color:#f05e29;
}
.font30{
	font-size: 20px;
}
.navbar-default .navbar-toggle .icon-bar{
	background: #f05e29!important;
}
.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
     background-color: #ffffff; 
}
@media(max-width: 1190px) {
	.navbar-fixed-top .navbar-brand {
    width: 185px;
    transition: width 500ms ease-in;
}
    .navbar-collapse.collapse {
        display: none !important;
    }
    .navbar-collapse.collapse.in {
        display: block !important;
    }
    .navbar-header .collapse, .navbar-toggle {
        display:block !important;
    }
    .navbar-header {
        float:none;
    }
	.navbar-fixed-top.scrolled .navbar-brand {
    width: 145px;
    transition: width 500ms ease-in;
}
button.navbar-toggle {
    margin-top: 10px;
}
.navbar-fixed-top.scrolled button.navbar-toggle {
    margin-top: 10px;
}
.navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse{
	max-height: auto;
}
.navbar-left{
	margin-left: 0px;
}
.navbar-fixed-top.scrolled .navbar-left, .navbar-fixed-top.scrolled .navbar-right{padding:0;}
.navbar-right .social_nav:last-child {
    margin-left: 0;
}
.nav>li>a {
    padding: 8px;
}

.navbar-right .social_nav {
    margin-top:0px; 
	display:inline-block;
}
.social_nav a {
    padding: 0;
    margin: 0px -20px 0px 8px;
}
.see_more_btn {
    margin-top: 25px;
}

}


@media(max-width: 1391px) {
	.navbar-brand>img{
		width: 100%;
	}
	.navbar-fixed-top {
    background-color: #0d133d !important;
    transition: background-color 500ms linear;
}
.carousel-caption h3 {
	color: #fff !important;
    font-size: 60px;
}
.carousel-caption p {
	color: #f05e28;
	font-weight:700;
	font-size: 34px;
}
.navbar-left {
    margin-left: 0px;
}
.navbar-right, .navbar-left {
    padding: 15px 0;
}
.carousel-caption {
    color: #fff !important;
    top: 22%!important;
    left: 11%!important;
    text-align: left;
}
}

@media(max-width: 991px) {
.see_more_btn {
    margin-top: 35px;
    margin-left: 0px;
}	
.box1{margin-right: 15px;}
.box1,.box2, .box3{
	margin: 0 auto;
	max-width:555px;
	max-height: 50vh;
}

}




@media(max-width: 767px){
	.foter ul {
    padding: 20px;
	margin-bottom: 0;
}
.foter li{
	display:block;
	margin-bottom: 5px;
}
.carousel-caption h3 {
	color: #fff !important;
    font-size: 25px;
}
.carousel-caption p {
	color: #f05e28;
	font-weight:700;
	font-size: 15px;
	line-height:0px;
}
.carousel-caption {
    color: #fff !important;
    top: 30%!important;
    left: 11%!important;
    text-align: left;
}
.navbar-right, .navbar-left {
    padding: 0px 0;
}
.pad0{padding: 15px!important;}
.sec3 .col-md-6:first-child {
    padding-right: 15px!important;
    padding-left: 15px!important;
}
.sec3 .col-md-6:last-child {
    padding-left: 15px!important;
	padding-right: 30px;
}
.sec4 .pad0 h1 {
	padding: 0 15px!important;
}
.sec4 .pad0 {
	padding: 0!important;
}
}



}
.mem_box {
    max-width: 332px;
    margin: 0 auto;
    margin-bottom: 15px;
}
.foter a:hover, .foter a:focus{
	color: #f05e29;
}
.sec2 h1{
	color: #ffffff;
}
.textsec li{
	line-height: 28px;
}
.sec2 p{
	margin-bottom: 60px;
}
.icon1{
	background:url(../images/icon1.png) top 5px left;
	background-repeat:no-repeat;
	padding: 0 35px;
}
.icon2{
	background:url(../images/icon2.png) top 5px left;
	background-repeat:no-repeat;
	padding: 0 35px;
}



</style>